import { render, staticRenderFns } from "./EntrypointEditAPI.vue?vue&type=template&id=19dd494e&scoped=true&"
import script from "./EntrypointEditAPI.vue?vue&type=script&lang=js&"
export * from "./EntrypointEditAPI.vue?vue&type=script&lang=js&"
import style0 from "./EntrypointEditAPI.vue?vue&type=style&index=0&id=19dd494e&prod&lang=scss&"
import style1 from "./EntrypointEditAPI.vue?vue&type=style&index=1&id=19dd494e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19dd494e",
  null
  
)

export default component.exports